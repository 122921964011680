.sidebar {
	flex-basis: 280px;
	min-width: 280px;
}

.sidebar .nav > ul {
	flex-direction: column;
	margin-top: 8px;
}

.sidebar .navbar-nav > li a { padding-left: 0; }
.sidebar .navbar-nav > li:last-of-type a { padding-right: 15px; }

.sidebar .dropdown-menu {
	position: static;
	background-color: transparent;
	text-align: left;
	box-shadow: none;
	border: none;
}

.sidebar .dropdown-menu > li {
	position: relative;
}

.sidebar .dropdown-menu > li::after {
	position: absolute;
	display: block;
	height: 1px;
	width: 100%;
	content: "";
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to right, var(--brightGold) 0%, white 100%);
}

.sidebar .dropdown-menu > li > a {
	margin-left: 20px;
	font-size: 20px;
}

.sidebar .navbar-nav  li:hover > a,
.sidebar .navbar-nav  li.active > a {
	color: var(--darkGold);
}

.sidebar .navbar-nav  li a {
	color: var(--dark);
}

.sidebar .bottom-menu__button {
	color: var(--normalPink);
}

.sidebar .navbar-toggle .icon-bar {
	background-color: var(--normalPink);
}





@media (width <= 991px) {
	.sidebar {
		flex-basis: 100%;
	}

	.sidebar .dropdown-menu > li {
		text-align: center;
	}
	.sidebar .navbar-nav .open .dropdown-menu > li > a {
		padding: 5px 15px;
		margin-left: 0;
	}

	.sidebar .dropdown-menu > li::after {
		background-image: linear-gradient(to right, white 0%, var(--brightGold) 50%, white 100%);
	}

	.sidebar .navbar-nav > li a {
		padding-left: 15px;
	}
}