.services {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 50px;
}

.services .standard {
	flex-basis: 19%;
	min-width: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.standard figcaption {
	position: relative;
	flex-grow: 1;
}

.standard figcaption a {
	display: block;
	text-align: center;
	margin-bottom: 35px;
	@apply --minionMediumIt;
	font-size: 23px;
	line-height: 1;
	color: var(--normalPink);
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.standard figcaption::after {
	position: absolute;
	bottom: 10px;
	left: calc(50% - 78px);
	content: "";
	width: 154px;
	height: 11px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJoAAAALCAMAAACwG2TDAAAAclBMVEUAAADd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dd2+Dk4uazN5QnAAAAJXRSTlMACQXO0rAa0MrDtS3VjkxBIhHIoZV+dmtkWjQfvJtdO6iekocohDXfgQAAAVxJREFUOMvNlEluxCAURIH/mbGhPc/uKfe/YrAjd9pKtkR5qxKFVEUtIISwUZATdKTkL/kZKEa2FSMDn0fyBsvLbr9KGUkM+8rpyvwUNc58ICwajTPP9yfk3OklGqLLE69H807Efot2/BT1NK6hm1/f/XHOCAvthNLpntpM8bTd4ggqs7TXTuLUhhh+GP5eU7KVvtvXwKGflUPNYWpuUiKPQyeD5RylvDUTcI1OzX14hdn7sutQXvthFCJY3940IvBuzaQEULJot/IfCdi2aQupAKTM1o4Dor613gYhxqG/loHseF6YsqrKqwGHyjxWSp4VAoBuAklIaDQAYPUkdH0YhQ7MdethCu6PZYfaFICqAKV57S/7pBXg1EaZkks7IVR2l77mWkGhEApTD+z7ztrVWZY9mn442tjZLFGn5bKY2R566JtHLFF36zmXURF5/8mspyQ51NtfOvx7PgHVAh+pSoY+1wAAAABJRU5ErkJggg==');
}

.standard:hover figcaption a {
	@apply --transitionOpacityEnd;
}

.standard img {
	width: 100%;
	max-width: 235px;
	height: auto;
	object-fit: cover;
	@apply --transitionOpacityStart;
	border: 1px solid var(--textPink);
}

.standard:hover img {
	@apply --transitionOpacityEnd;
}





@media (width <= 1199px) {

	.services {
		justify-content: space-around;
	}

	.services .standard {
		flex-basis: 30%;

	}

}