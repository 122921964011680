.blog-item h2 {
	text-align: left;
}

.blog-item img {
	max-width: 100%;
	margin: 10px 0;
	border: 1px solid var(--textPink);
}

.blog-item a,
.blog-item h2 {
	@apply --transitionOpacityStart;
}

.blog-item a:hover,
.blog-item h2:hover {
	@apply --transitionOpacityEnd;
	text-decoration: none;
}

.blog-item:not(:last-child) {
	margin-bottom: 80px;
	position: relative;
}

.blog-item:not(:last-child)::after {
	position: absolute;
	bottom: -40px;
	left: 0;
	width: 100%;
	height: 1px;
	content: "";
	background-image: linear-gradient(to right, white 0%, var(--textPink) 50%, white 100%);
}