.advantages {
	margin: 25px 20px 25px 0;
	width: 350px;
	height: 450px;
	background-image: url(../img/advantages.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center right;
	position: relative;
}

.advantages-item {
	position: absolute;
	@apply --minionSemiboldIt;
	transition: color .3s, transform .3s;
	color: var(--darkSilver);
	font-size: 16px;
	width: 150px;
	height: auto;
	display: block;
	text-align: center;
	cursor: pointer;
	transform: scale(.95);
}

.advantages-item:hover {
	color: var(--brightGold);
	will-change: transform;
	transform: scale(1);
}

.advantages-item:nth-child(1) { top: -5px;left: 180px; }
.advantages-item:nth-child(2) {	top: 70px;left: 85px; }
.advantages-item:nth-child(3) { top: 160px;left: 60px; }
.advantages-item:nth-child(4) { top: 250px;left: 150px; }
.advantages-item:nth-child(5) {	top: 350px;left: 100px; }
.advantages-item:nth-child(6) {	top: 420px;left: 230px; }




@media (width <= 1199px) {
	.advantages {	height: 530px; }
	.advantages-item:nth-child(1) { top: -5px;left: 150px; }
	.advantages-item:nth-child(2) {	top: 75px;left: 65px; }
	.advantages-item:nth-child(3) { top: 180px;left: 120px; }
	.advantages-item:nth-child(4) { top: 300px;left: 120px; }
	.advantages-item:nth-child(5) {	top: 410px;left: 70px; }
	.advantages-item:nth-child(6) {	top: 490px;left: 220px; }
}





@media (width <= 991px) {
	.advantages {
		flex-basis: 100%;
		background-position: center;
		height: 435px;
		width: 224px;
	}
	.advantages-item:nth-child(1) { top: -5px;left: calc(50% - 50px); }
	.advantages-item:nth-child(2) {	top: 65px;left: calc(50% - 150px); }
	.advantages-item:nth-child(3) { top: 140px;left: calc(50% - 60px); }
	.advantages-item:nth-child(4) { top: 245px;left: calc(50% - 80px); }
	.advantages-item:nth-child(5) {	top: 330px;left: calc(50% - 140px); }
	.advantages-item:nth-child(6) {	top: 400px;left: calc(50% - 0px); }
}