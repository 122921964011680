.breadcrumbs {
	margin-top: 13px;
	margin-bottom: 15px;
	padding: 15px 0;
}

.breadcrumbs a {
	@apply --robotoNormal;
	font-size: 14px;
}

.breadcrumbs a:not(.active) {
	color: var(--dark);
}

.breadcrumbs a:not(:first-child) {
	position: relative;
	margin-left: 15px;
}

.breadcrumbs .active,
.breadcrumbs a:hover,
.breadcrumbs a:active {
	color: var(--darkSilver);
}

.breadcrumbs a:not(:first-child)::after {
	position: absolute;
	top: -1px;
	left: -10px;
	display: block;
	content: ">";
	font-size: 14px;
}





@media (width <= 991px) {
	.breadcrumbs {
		text-align: center;
	}
}