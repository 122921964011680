.header-and-menu-wrapper {
	background-color: var(--normalPink);
	padding: 20px 0 10px;
}

.header-block:first-child img {
	@apply --transitionOpacityStart;
}

.header-block:first-child img:hover {
	@apply --transitionOpacityEnd;
}
