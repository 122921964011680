.main-text {
	flex-basis: calc(100% - 370px);
}

.main-text h1,
.main-text h2 {
	@apply --robotoMedium;
	font-size: 26px;
	color: var(--normalPink);
}

.main-text ul ,
.main-content ul {
	list-style: none;
}

.main-text p,
.main-text ul li,
.main-content ul li {
	@apply --robotoNormal;
	color: black;
	font-size: 16px;
}

.main-text ul li,
.main-content ul li {
	position: relative;
	margin-bottom: 10px;
}


.main-text__list {
	padding-left: 20px;
}

.main-text__list p {
	position: relative;
	padding-left: 25px;
}

.main-text__list p::before,
.main-text ul li::before,
.main-content ul li::before {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	left: 0;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAe1BMVEUAAAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qADkqgvOAAAAKHRSTlMAAvjwphjf2p13Wkoz/PTi1L6BfXA+LOrKv7WumJONh4VrUEQ5IxEP904xYwAAAJNJREFUGNOdzkcOwyAQQFFiwL239N7+/U8YEyxIlskXQpqnWYz4sYXP09eC+4M1R5HRFLmZ3zgoCTeSCraxQRNcESEI4phpy7weMnGgInw+JLVFFapc0HVJoEW9iSwKIMXUFzAasTruCSg1pO7Mgeg+YXqm+Tj0RC5BU1qbdUlGq7DmNNrRytm8Jqy0M6cXL17/6QW8+wwzPH2yTgAAAABJRU5ErkJggg==');
}

.main-text ul li::before,
.main-content ul li::before {
	left: -30px;
}





@media (width <= 991px) {
	.main-text {
		flex-basis: 100%;		
	}
}