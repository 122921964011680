.contacts-item-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}

.contacts-item {
	padding-left: 60px;
	position: relative;
	margin: 10px;
}

.contacts-item p {
	@apply --robotoNormal;
	font-size: 18px;
	color: var(--normalPink);
}

.contacts-item span,
.contacts-item a {
	display: block;
	color: black;
	font-size: 18px;
	margin-bottom: 10px;
}

.contacts-item span {
	@apply --robotoNormal;
}

.contacts-item a {
	@apply --robotoBold;
	@apply --transitionOpacityStart;
}

.contacts-item a:hover {
	text-decoration: none;
	@apply --transitionOpacityEnd;
}

.contacts-item::before {
	width: 46px;
	height: 46px;
	display: block;
	border-radius: 50px;
	border: 1px solid var(--brightGold);
	filter: grayscale(1);
	transition: filter .3s;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}

.contacts-item:first-child::before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAjVBMVEUAAAD3tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD0/BXkMAAAALnRSTlMAAeL0OgTeuKd/VBMJ+e3phFpQLPrPyLChm5RwaF1XTUEYDuTc1b+MeDMqH2RJ0D89JAAAAQlJREFUSMft09luwjAQBVC7MVmahSwEsjZp2KGd//+8Qm2nC5JzK1XihftonYc71gx75M7hoqoEh3W6IbKfUS5suuQJ9INL9OlfIN5bpHyCcE/pax9g3oLG7M7T3P3im+Of+Op1mlejzgUwaq31EtFMRFJbCUPiO5Iv4hnkG5JZv0P8vMQ+Rqe1VB0PqjOUJDNvOeIzXcdOoTrpWvtE9RlOhkF4Y+k+B//6cCrzneFeAk/7cNVf2jkT+x/Ei3Htvc5R92XyofZhRKQ8B/oQdF/BIbrxdm/4n3Z740tmyNH9Xagwb3Od/9DzjhnDs3j7Tb9NLt0sqx05c+h0yIpyXzT7wt2nPnvk3/MBZaA7OOxBjxcAAAAASUVORK5CYII=');
}

.contacts-item:last-child::before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAkFBMVEUAAAD3tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD0QhlSBAAAAL3RSTlMA9zVIEwYoFwnLDNK6aGLq1sK+fHYkrqSXjlMexapwP9+ekoiDWfDk27NELg9N4L88+e0AAAHjSURBVEjH7ZPZlqowEEUTEoJhnieZVHBq7fP/f3cVb7egNrL6ufcDK4RDVZ1KhfzxG6JaWcwWLzcutpk+U20boJ6Lzbw6EsCruTBQvxcvLAfb4FKHXqB4W44SAwflurLMDMG0mLcO9nYf8+RXqusoU2omgVV0Wx/3gjCU6kT3ACP/+p6m1we6H8RqZ2C71r4dH9jlKfaN9lL9sQJ8NrDs8d4v0ldq6xPbkA821sktiUnZc/dMIFZGPgy26F1EtOBjsRY42FnqOBs1zbKy7SNLUI1De8BKPPgu0rwKN7Hv+Z7TiKHHPYycPJAby75yvtREiHYwIgnK516l2WA8Ed9fxI6KJ7Vesnt2iXBQ+dap+enB/NEVX8ntHfxBdk3CPRz8OLDtM1OUeqleulhkg/mMIzIgBLLcCoLS96UjpZ+sSvdwi6eHDj4rfdx1D2Wf9sRbKURttYF9U7CnDve7FNUtmOxG80nh2uSZNZq+vE6e7ueUS9AsIi/QXKTq9bd2cLspvCN5TQew6/Adv293Axpo5AfUBAYnucv/H0wMmMrU9XfQkmTdDyWvdmhClUwRYmfJPiAz++5Noxeg2TV0RrHvVPKOmmJ94rkBmmpkBhtQ0wSKM5nFImgAI9TIXD7OLCJ//IJ/VQwpFu+Js6sAAAAASUVORK5CYII=');
}

.contacts-item:hover::before {
	filter: grayscale(0);
}

.contacts-map h2 {
	font-size: 18px;
	background-color: var(--lightSilver);
	padding: 10px;
	border-radius: 2px;
}

.map-item {
	padding: 10px;
	background-color: var(--lightSilver);
	display: flex;
	flex-wrap: wrap;
	margin: 5px 0;
}

.map,
.map-data {
	padding: 10px;
	flex-basis: 48%;
	max-width: 48%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.map-text span:first-child {
	@apply --minionSemiboldIt;
	font-size: 23px;
	color: var(--brightGold);
	background-color: var(--normalPink);
	display: inline-block;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	text-align: center;
	margin-right: 20px;
}

.map-text span:last-child {
	@apply --robotoNormal;
	font-size: 16px;
	color: black;
}

.map-photo {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.map-photo img {	
	flex-basis: 49%;
	width: 49%;
	flex-grow: 0;
	margin: 0;
	object-fit: cover;
}





@media (width <= 767px) {

	.contacts-item-wrapper {
		justify-content: space-around;
	}
	
	.map,
	.map-data {
		padding: 10px;
		flex-basis: 100%;
		max-width: 100%;
		flex-wrap: wrap;
	}

	.map-data {
		order: -1;
	}
}
