.seo-text-wrapper {
	padding: 40px 0 30px;
	background-color: var(--lightSilver);
}

.seo-text {
	column-count: 2;
	column-gap: 50px;
}

.seo-text p {
	color: var(--darkSilver);
	@apply --robotoNormal;
	font-size: 14px;
}





@media (width <= 767px) {
	.seo-text-wrapper {
		margin: 0 -15px;
		padding-left: 15px;
		padding-right: 15px;		
	}

	.seo-text {
		column-count: 1;
	}
}