.main-slider {
	margin: 20px 0;
}

.main-slider a {
	display: block;
	border: 2px solid var(--whitePink);
	padding: 3px;
}

.main-slider img {
	width: 100%;
	height: auto;
	border: 1px solid var(--brightGold);
}

.owl-pagination {
	margin-top: -50px;
}

.owl-theme .owl-controls .owl-page span {
	position: relative;
	z-index: 2;
	opacity: 1;
	background-color: transparent;
	border: 1px solid var(--brightGold);
}

.owl-theme .owl-controls .owl-page.active span {
	border: 1px solid var(--normalPink);
	background-color: var(--normalPink);
}




@media (width <= 991px) {
	.owl-pagination {
		margin-top: -40px;
	}
}





@media (width <= 767px) {
	.main-slider { display: none; }
}