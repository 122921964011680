/* variables */
:root {
	--whitePink: #714860;
	--textPink: #9a6c88;
	--lightPink: #5e2d4a;
	--normalPink: #380b24;
	--darkPink: #310923;
	--lightSilver: #e3e3e3;
	--silver: #cecece;
	--darkSilver: #8e8686;
	--dark: #370b26;
	--gold: #ffc45f;
	--brightGold: #ffb736;
	--darkGold: #cd9204;
}

/* properties */
:root {

	--transitionOpacityStart {
		will-change: opacity;
		opacity: 1;
		transition: opacity .3s;
	};
	--transitionOpacityEnd {
		opacity: .7;
		transition: opacity .3s;
	};
	
	--transitionColor {
		transition: color .3s;
	};

	--minionMediumIt {
		font-family: tk-minion-pro;
		font-weight: 500;
		font-style: italic;	
	};
	
	--minionSemiboldIt {
		font-family: tk-minion-pro;
		font-weight: 600;
		font-style: italic;	
	};
	
	--minionBoldIt {
		font-family: tk-minion-pro;
		font-weight: 700;
		font-style: italic;	
	};
	
	--robotoNormal {
		font-family: Roboto;
		font-weight: 400;
	};

	--robotoMedium {
		font-family: Roboto;
		font-weight: 500;
	};

	--robotoBold {
		font-family: Roboto;
		font-weight: 700;
	};
	
}