.site-footer-wrapper {
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
}

.site-footer__column--logo {
	flex-basis: 185px;
	margin-right: 40px;
	text-align: center;
}

.site-footer__column--logo img { @apply --transitionOpacityStart; }
.site-footer__column--logo img:hover { @apply --transitionOpacityEnd; }

.site-footer__column--logo p {
	@apply --minionBoldIt;
	font-size: 20px;
	color: var(--darkSilver);
	margin-bottom: 15px;
}

.site-footer__social a {
	width: 46px;
	height: 46px;
	margin: 0 1px;
	display: inline-block;
	border-radius: 50px;
	border: 1px solid var(--brightGold);
	background-size: cover;
	filter: grayscale(1);
	transition: filter .3s;
}

.site-footer__social a:hover { filter: grayscale(0); }
.site-footer__social--Vk { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAe1BMVEUAAAD3tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD0YbhMGAAAAKHRSTlMAtMP6hmdDH/GdXQoB9Ne7kIx9U0gG7OTIsaqklYFvOS8pAujOzUwXTn0eHAAAANhJREFUSMftkkkSgyAQRRsU0EwaNZOzGb3/CdPdWuVC5ACWb8MveHyEEjY2VsMBiItO4tJPL243i6SUuQHoTj1RA/J9CCSx6JolH88wV0olzu2HnZFFf+a0cjOY3pTOOKfYPmqw0Hq0FmKSo/67sZ6AFUFrXsY6f1bMtly4dMP19wN8Br050nhtYYGK28Is4NLdi4ZA293pIfCQiRCWSfsZMTjwZ3qwc/li3l87dBNNtecheK7+fTHqAiAcfe36LXXB713h1vEqd3DSpUopQ0kJ5KFgY2MV/AHumyR9mcSfJQAAAABJRU5ErkJggg=='); }
.site-footer__social--Fb { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAV1BMVEUAAAD3tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD3lYYllAAAAHHRSTlMA7h3q31gW/dciE9GxowZUD/Paw28wt5GCZT47NS7c4gAAAI9JREFUSMft0csOAiEMQFGUWmhnQOblk///TqVu3BhKjCu5G1ickKaYXu9DaT15AAg6vfkseZVGyK8GFb+IVb8+iLWzbna2RQeM0Wja7wo/GmXYwCPyJvzKjPVpRmutK3x6Xs5V7vNbY32JP+VLdk5oOUOVM9FdNrMSUWrcu/5XD51/zacmnhYAmG+m9yc9AM6VEfs+Mx7QAAAAAElFTkSuQmCC'); }
.site-footer__social--Tw { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAdVBMVEUAAAD3tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD3kP+AAAAAAJnRSTlMA+UjoMR715NidPRULBNPNw7ivmYJybUEpD+3gyLyldlXw3I1gOeMKsY4AAADDSURBVEjH7dFHDoMwFEXRZ2N6S+ikAGne/xJDCDIC9M0wGXBGT+IKWTZ2uz8WYsKvhp14CLkJStqpWcgPy42TEITgZPFxCia/zkUHgn+SLAuG6coRcwR5dKf/Hj/9fh7kyDFByofCTotS5S5IQXWUSw+Qaida5S1oR7lSgiasZW3U0OAxm+cudMzMntVMQMc35EwDPW/Wn31sMLOLqq0Sm4R60Bvf+LPn5dPVX17Qqxqm4nsbYFOVJ5FtRNeU19jtfuANLDAkWOIa2yMAAAAASUVORK5CYII='); }
.site-footer__social--Ig { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAilBMVEUAAAD/uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB//uB/NER4JAAAALXRSTlMAQMCQMB8EzvrmqkgRDcmveE8YFfbs35h/azoJ8tfSx7u0ootjQSSehnFYNij/+aNvAAABC0lEQVRIx+3St5KDQBBF0UaDRwgJWUDerb3//3sLVVoIhhk20wa68Qm6qp+8enLVRzbq67h2e3QaYCqc6XyKucjX+Am93ftmc1kB+jkj9O5SVwLOn/hY6rx/whOpuw7x5cYde58HYB8EOwb4Idc+YeHRWB4pOw+UWsC128UWJurNxJXMgbm0ZRCLZ+ZfsJAuBRMbd4FU2s4N/7Yf063VjyAT18xlD0G71gIorHwKnB8+CSHMrTyNgCDxRe6KuotYuThhoxarJU2TaoBLsqVtNJchLl72u7SpL0O8ySvWp1jNKpEmx8b1br38CKvE0bvFQKnxNZZyjbuhWceiN4uMOpWefNfpq8zl1bP7AWUsWZDrKiw0AAAAAElFTkSuQmCC'); }

.site-footer__column--about {
	flex: 1 0 130px;
}

.site-footer__heading {
	@apply --robotoMedium;
	font-size: 22px;
	color: var(--darkSilver);
}

.site-footer__column:not(.site-footer__column--logo) a,
.site-footer__column:not(.site-footer__column--logo) p {
	display: block;
	@apply --robotoNormal;
	font-size: 18px;
	color: var(--dark);
	margin-bottom: 15px;
}

.site-footer__column.site-footer__column--address p {
	padding-left: 25px;
	position: relative;
}

.site-footer__column.site-footer__column--address p::before {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	left: 0;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAe1BMVEUAAAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qAD/qADkqgvOAAAAKHRSTlMAAvjwphjf2p13Wkoz/PTi1L6BfXA+LOrKv7WumJONh4VrUEQ5IxEP904xYwAAAJNJREFUGNOdzkcOwyAQQFFiwL239N7+/U8YEyxIlskXQpqnWYz4sYXP09eC+4M1R5HRFLmZ3zgoCTeSCraxQRNcESEI4phpy7weMnGgInw+JLVFFapc0HVJoEW9iSwKIMXUFzAasTruCSg1pO7Mgeg+YXqm+Tj0RC5BU1qbdUlGq7DmNNrRytm8Jqy0M6cXL17/6QW8+wwzPH2yTgAAAABJRU5ErkJggg==');
}


.site-footer__column--service {
	flex: 1 0 250px;
}

.site-footer__column--address {
	flex: 1 0 435px;
}





@media (width <= 1199px) {
	.site-footer__column--address {	flex: 1 0 235px; }
	.site-footer__heading { font-size: 20px; }
	.site-footer__column:not(.site-footer__column--logo) p { font-size: 16px;	}
}





@media (width <= 991px) {
	.site-footer-wrapper {
		flex-wrap: wrap;
		padding: 15px 25px;
	}

	.site-footer__column--address {
		margin-top: 20px;
		flex-basis: 100%;
	}

	.site-footer__column--address .site-footer__heading {
		text-align: center;
	}

	.site-footer__column--address p {
		margin-left: 20px;
	}
}





@media (width <= 767px) {
	.site-footer__column--logo {
		flex-basis: 100%;
		margin: 0;
	}

	.site-footer__column--about,
	.site-footer__column--service {
		flex-basis: 50%;
	}
}





@media (width <= 479px) {
	.site-footer__column--about,
	.site-footer__column--service {
		flex-basis: 100%;
		margin-top: 20px;
		text-align: center;
	}
}