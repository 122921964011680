.remodal-overlay {
	background-color: rgba(159, 140, 151, .5);
}

.remodal {
	width: 100%;
	max-width: 610px;
	border-radius: 5px;
	background-color: #FFF;
}

.remodal::after {
	position: absolute;
	content: "";
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	top: 10px;
	left: 10px;
	border: 1px solid var(--brightGold);
	border-radius: 5px;
	pointer-events: none;
}

.remodal-close {
	top: 20px;
	right: 20px;
	border: 1px solid var(--brightGold);
	border-radius: 50%;
	color:var(--brightGold)!important;
	width: 25px;
	height: 25px;
	transform: rotate(0deg);
	transition: transform .3s;
}

.remodal-close:hover {
	transform: rotate(360deg);
}

.remodal-close::before {
	line-height: 25px;
	width: 100%;
}

.remodal p {
	@apply --robotoBold;
	font-size: 18px;
	text-transform: uppercase;
	color: var(--darkPink);
	margin-bottom: 40px;
}

.remodal input:nth-child(1),
.remodal input:nth-child(2) {
	width: 70%;
	display: block;
	margin: 10px auto;
	background-color: #f6f6f6;
	border: 1px solid #e1c8c0;
	border-radius: 2px;
	color: #736d70;
	font-size: 14px;
	text-transform: uppercase;
	@apply --robotoNormal;
	padding: 5px 10px;
}

.remodal input:nth-child(3) {
	margin: 20px auto 10px;
	color: var(--normalPink);
	font-size: 14px;
	@apply --robotoBold;
	text-transform: uppercase;
	background-image: linear-gradient(to top, var(--gold) 0%, var(--brightGold) 100%);
	border: none;
	padding: 5px 20px;
	border-radius: 2px;
	@apply --transitionOpacityStart;
}

.remodal input:nth-child(3):hover {
	@apply --transitionOpacityEnd;
}




@media (width <= 479px) {
	.remodal input:nth-child(1),
	.remodal input:nth-child(2) {
		width: 95%;
	}
}