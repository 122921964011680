.main-text-and-advantages-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.block-with-sidebar {
	display: flex;
	flex-wrap: wrap;
}

.main-content {
	flex-basis: calc(100% - 280px);
	position: relative;
	margin-bottom: 40px;
}

.main-content::after {
	position: absolute;
	display: block;
	height: 1px;
	width: 100%;
	content: "";
	bottom: -20px;
	left: 0;
	background-image: linear-gradient(to right, white 0%, var(--brightGold) 50%, white 100%);
}

.site-main {
	max-width: 100%;
	overflow-x: hidden;
}







@media (width <= 991px) {
	.main-text-and-advantages-wrapper {
		justify-content: space-around;
	}

	.main-content {
		flex-basis: 100%;
		margin-top: 25px;
	}

}





@media (width <= 767px) {
	.site-main {
		padding: 0 15px;
	}
}