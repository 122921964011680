.header-menu {
	padding-top: 10px;
}

.navbar-collapse {
	padding: 0;
}

.navbar-nav::before,
.navbar-nav::after {
	display: none;
}

.navbar-nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.navbar-nav > li a {
	flex-grow: 1;
	color: var(--gold);
	@apply --minionMediumIt;
	font-size: 23px;
	@apply --transitionColor;
}

.navbar-nav > li:hover > a, 
.navbar-nav > li.active > a {
	color: var(--silver);
}

.navbar-nav > li:first-of-type a {
	padding-left: 0;
}

.navbar-nav > li:last-of-type a {
	padding-right: 0;
}

.nav > li > a:hover,
.nav > li > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent;
}

.dropdown-menu {
	background-color: var(--normalPink);
	text-align: center;
	left: -80px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	color: var(--silver);
	background-color: transparent;
}





@media (width <= 991px) {
	.navbar-toggle {
		display: flex;
		align-items: baseline;
		float: none;
		margin-right: auto;
		margin-left: auto;
		padding: 1px 6px 1px 12px;
	}

	.bottom-menu__button {
		float: left;
		margin-right: 15px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 20px;
		@apply --minionBoldIt;
		color: var(--brightGold);
	}

	.bottom-menu__hamburger {
		float: left;
	}

	.navbar-toggle .icon-bar {
		width: 21px;
		background-color: var(--brightGold);
		transition: all .3s;
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-child(1) {
		position: absolute;
		top: 6px;
		transform: rotate(45deg);
		transform-origin: left center 0;
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-child(2) {
		opacity: 0;
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-child(3) {
		position: absolute;
		top: 17px;
		transform: rotate(-45deg);
		transform-origin: left center 0;
	}

	.navbar-collapse {
		box-shadow: none;
	}

	.navbar-nav {
		justify-content: center;
		margin: 0;
	}

	.navbar-nav > li {
		width: 100%;
		text-align: center;
	}

	.navbar-nav > li:first-of-type a {
		padding-left: 15px;
	}

	.navbar-nav > li:last-of-type a {
		padding-right: 15px;
	}
}





@media (width <= 767px) {
	.site-header .navbar-nav {
		background-color: var(--brightGold);
	}

	.navbar-nav > li {
		border-bottom: 1px solid rgba(255,255,255,.4);
		transition: border-bottom-color .3s;
	}

	.site-header .navbar-nav > li:hover,
	.site-header .navbar-nav > li.active {
		border-bottom: 1px solid var(--normalPink);
	}

	.navbar-nav > li a,
	.navbar-nav > li:hover > a,
	.navbar-nav > li.active > a {
		color: var(--normalPink);
	}

	.navbar-nav > li:hover > a,
	.navbar-nav > li.active > a {
		font-weight: bold;
	}

	.header-and-menu-wrapper {
		padding-bottom: 0;
	}
}