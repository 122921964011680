.header-content {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	height: 175px;
	position: relative;
}

.header-content::after {
	position: absolute;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background-image: linear-gradient(to right, #310b20 0%, #ffb736 44%, #440b2c 100%);
	bottom: 0;
	left: 0;
}

.header-block {}

.header-block--logo {
	margin-right: 90px;
}

.header-block--text {
	width: calc(100% - 290px);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.header-block--text p:first-child {	@apply --minionBoldIt; }
.header-block--text p:last-child { @apply --minionMediumIt; }


.header-block__element span{
	display: block;
	margin-top: 5px;
}

.header-block__element-heading {
	color: var(--textPink);
	font-size: 16px;
	@apply --robotoNormal;
}

.header-block__time {
	color: var(--silver);
	font-size: 16px;
	@apply --robotoNormal;
}

.header-block__phone-link {
	color: var(--silver);
	font-size: 20px;
	@apply --robotoBold;	
}

.header-block__phone-link:link {
	color: var(--silver);
	text-decoration: none;
}

.header-block--text p {
	font-size: 20px;
	color: var(--silver);
	margin-bottom: 0;
}


.header-block--links {
	width: calc(100% - 290px);
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
}

.header-block--links a {
	display: block;
	padding: 7px 15px 7px 60px;
	@apply --robotoNormal;
	position: relative;
	font-size: 16px;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.header-block--links a:hover {
	@apply --transitionOpacityEnd;	
}

.header-block--links a:nth-child(1) {
	color: var(--silver);
}

.header-block--links a:nth-child(1)::before {
	position: absolute;
	content: "";
	top: -5px;
	left: 0;
	width: 45px;
	height: 45px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAMAAAANxBKoAAAAXVBMVEUAAAD/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzYOWZcmAAAAHnRSTlMA8boNFdzWqxHruEQEyTgK5LWvnsCkTy384tGVIhz/Mw8MAAAAnUlEQVRIx+3SyQrDIBSFYWPSOhuHDB19/8fsotEicqPr4r9y8XFAuKjXi1FvGGPGT036Gb75FjxfD32hDXpMevpjLZOea1QLsd0PbTYhtDzTa8hzp3okGcZT5Y8OwNV1TkFVchxxndeX9Z74b3nXgGZLPNr1ZuOyZYAeFIpcjPGJB0gvqIyD+iHLq1GQJkFxnMdVsIB+k6HMvVCv9wH3EBPT+240YgAAAABJRU5ErkJggg==');
}

.header-block--links a:nth-child(2),
.header-block--links a:nth-child(3) {
	padding-left: 40px;
	border-radius: 5px;
	border-bottom: 1px solid var(--lightPink);
	background-image: linear-gradient(to top, var(--darkPink) 0%, var(--whitePink) 100%);
	box-shadow: 0 2px 5px 2px var(--darkPink);
	color: var(--brightGold);
}

.header-block--links a:nth-child(2)::before,
.header-block--links a:nth-child(3)::before {
	position: absolute;
	content: "";
	top: 5px;
	left: 10px;
	width: 25px;
	height: 25px;
}

.header-block--links a:nth-child(2)::before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAgVBMVEUAAAD/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzb/tzaixwC/AAAAKnRSTlMA1ImFWh3y9MVeJSEN+tJnYFWakjksEwaMcygI2q+nbWxJRT80HObitATbrB26AAAAtElEQVQoz7XR1w6DMAxAURcwUBL2CHvT4f//wEoRIi3pY3tfjxTFNvwhXgaXoyDlSkLybWfP9mlUYlDFGMoYq6hUYpIFT08mICbjXa6QkCwB6yyhKxvPEsGRJvkMWQZzrr/W+XXb1s5Dl8wS0yTiTJclauK4iRZdeqcOLp7d6T/AFfIcVvwyT1KYZpGGuiQ3z3XZvT+LBaIAzqFo9r2pXSNuDJFtiAOVn/c58mlQwlPtpj/uBcYfES2q2W5NAAAAAElFTkSuQmCC');
}

.header-block--links a:nth-child(3)::before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAbFBMVEUAAAD3tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD33tD0DpN6QAAAAI3RSTlMA+PEQmINsUEs+LAr77N7JuLCnnWckHQgF6uXY0MCMizcxF9zyjdMAAACJSURBVCjPvdDZDsIgEIVhoVCk+2Zb9+W8/zs6kxiT1uOl/pd8Ccyw+XMxfIGtNXcKwQIdg90egInsLmgtkcyqXIlMtUAzs4dSkTpnkpyEPN+nAIqBkte5U90gW8ncQ3JjrOxlXFLeKR1KANUwLcnhXVjPXr6geXz8RW8U2oSMePPnoxOgyfkPegJD4gv8fWrISAAAAABJRU5ErkJggg==');
}





@media (width <= 1199px) {
	.header-block--logo {	margin-right: 30px;	}	

	.header-block--text,
	.header-block--links {
		width: calc(100% - 230px);
	}
}





@media (width <= 991px) {
	.header-content {
		height: auto;
		flex-direction: row;
	}

	.header-block--logo { margin-right: 90px; }

	.header-block--text {
		width: calc(100% - 290px);
		justify-content: flex-start;
	}

	.header-block__element:nth-child(1) {	margin-bottom: 20px; }
	.header-block__element:nth-child(2) {	margin-right: 40px;	}

	.header-block--links {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}





@media (width <= 767px) {
	.header-content {
		padding: 0 15px;
	}

	.header-block {
		width: 100%;
	}

	.header-block--logo {
		text-align: center;
		margin: 0;
	}

	.header-block--text {
		justify-content: space-around;
	}

	.header-block__element:nth-child(1) {
		width: 100%;
		text-align: center;
	}

	.header-block__element:nth-child(2),
	.header-block__element:nth-child(3) {
		text-align: center;
		margin-right: 0;
	}

	.header-block__element span,
	.header-block__element a{
		display: inline-block;		
	}

	.header-block__element span:first-child {
		margin-right: 5px;
	}

	.header-block--links a:nth-child(1),
	.header-block--links a:nth-child(2),
	.header-block--links a:nth-child(3) {
		padding: 35px 10px 7px;
		margin: 0 5px;
		text-align: center;
		font-size: 14px;
	}

	.header-block--links a:nth-child(1)::before {
		left: calc(50% - 22px);
	}

	.header-block--links a:nth-child(2)::before,
	.header-block--links a:nth-child(3)::before {
		left: calc(50% - 12px);
	}
}





@media (width <= 479px) {

	.header-block--text p { font-size: 16px; }
	.header-block--links { flex-wrap: wrap; }

	.header-block--links a:nth-child(1),
	.header-block--links a:nth-child(2),
	.header-block--links a:nth-child(3) {
		padding: 7px 15px;
		margin: 10px 0;
		width: 100%;
	}

	.header-block--links a:nth-child(1) {
		border-radius: 5px;
		border-bottom: 1px solid var(--lightPink);
		background-image: linear-gradient(to top, var(--darkPink) 0%, var(--whitePink) 100%);
		box-shadow: 0 2px 5px 2px var(--darkPink);
		color: var(--brightGold);
	}

	.header-block--links a:nth-child(1)::before,
	.header-block--links a:nth-child(2)::before,
	.header-block--links a:nth-child(3)::before {
		display: none;
	}
}