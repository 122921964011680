.main-content h1,
.main-content h2 {
	color: var(--darkPink);
	font-size: 23px;
	@apply --robotoBold;
	text-align: center;
}

.main-content h3 {
	color: var(--darkPink);
	font-size: 20px;
	@apply --robotoBold;
	text-align: center;
}

.catalogue-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.catalogue-wrapper .standard {
	flex-basis: 30%;
	min-width: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 15px 5px;
}

.main-content img {
	flex: 1 0 auto;
	object-fit: contain;
	margin: 10px;
}

.table > tbody + tbody {
	border-top: none;
}

.table {
	@apply --robotoNormal;
	font-size: 16px;
	color: var(--dark);
	margin: 20px 0;
}

.main-content p,
.main-content span {
	@apply --robotoNormal;
	color: black;
	font-size: 16px;
}

.example-wrapper {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;	
}

.main-content .example-wrapper img {
	flex-basis: 30%;
	min-width: 200px;
	height: auto;
	object-fit: contain;
	border: 1px solid var(--lightSilver);
	max-width: none;
}

.main-content .catalogue-wrapper img, 
.main-content .catalogue-wrapper a img {
	max-width: none;
}

.catalogue-button {
	font-size: 14px;
	@apply --robotoBold;
	background-image: linear-gradient(to top, var(--darkPink) 0%, var(--lightPink) 100%);
	border-radius: 5px;
	color: white;
	padding: 15px 0px;
	width: 320px;
	display: block;
	text-transform: uppercase;
	text-align: center;
	margin: 40px auto 20px;	
	@apply --transitionOpacityStart;
}

.catalogue-button:hover {
	text-decoration: none;
	color: white;
	@apply --transitionOpacityEnd;
}

.main-content img {
	max-width: calc(25% - 20px);
}

.main-content.main-content--blog img { max-width: 100%; }

.main-content h1 + img {
	float: left;
}





@media (width <= 767px) {
	.catalogue-button {
		font-weight: 400;
		font-size: 12px;
		width: 280px;
	}

	.main-content img {
		max-width: calc(33% - 20px);
	}

	.main-content.main-content--blog img { max-width: 100%; }
}





@media (width <= 479px) {
	.main-content img {
		max-width: calc(50% - 20px);
	}

	.main-content.main-content--blog img { max-width: 100%; }
	.services .standard { flex-basis: 100%; }
}





@media (width <= 320px) {
	.main-content img {
		width: 100%;
		max-width: 100%;
		flex-basis: 100%;
	}
}