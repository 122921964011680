.sites-toggler {
	background-color: var(--lightPink);
	padding-top: 5px;
}

.sites-toggler__link {
	font-family: "Minion Pro";
	font-size: 18px;
	font-weight: 500;
	font-style: italic;
	line-height: 24px;
	position: relative;
	padding: 5px 15px 2px 30px;
	margin-right: 5px;
	margin-top: 2px;
	border-radius: 2px 2px 0 0;
	display: inline-block;
}

.sites-toggler__link::before {
	position: absolute;
	top: 9px;
	left: 10px;
	content: "";
	width: 15px;
	height: 15px;
}

.sites-toggler__link.sites-toggler__link--current::before {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAaVBMVEUAAADR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dGSGPszAAAAInRSTlMArtDxqtXIurSk+euniHEF2X1dV1Iy4b+WSToqG+Xid2FMA3uX7wAAAIpJREFUCNeFjkcOw0AMAyVtr+7dafr/I7NGjMA3z4UgQBAD91SdMdj3aExXHf0lA0uiyEE2pQ6CJi2ZUU8kBoD01G1Wda1yq8cEkC22DcaIzRttLvvAynIBFYe1HPTez4JZzN53cJAYiVlbTqeAHcu/e5jqNNopCuHU/ldcXE3yc3FenVvgyrb98guTTQfHwqeJlQAAAABJRU5ErkJggg=='); }
.sites-toggler__link:not(.sites-toggler__link--current)::before { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAWlBMVEUAAAD/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHL/zHK8789TAAAAHXRSTlMAytP49PDm4NubbkcnGhO+sq+mj4lUNSMLzsXEuywZZ3wAAAB1SURBVAjXTYpZFoMgFEPDJCiDVq3agf1vsxDp0fvx8u5JUNnWj3v1Ho0+n4w79V1/acox+79VaaphgcCpTg/mgIWp4sQUEOXOIoezt3Bc+eNL7yA52w7ZfM7EKIaEz3cWQNw9AVFf+kQhyGa6KBlG11mxRgA/y6sQ6NhW3GwAAAAASUVORK5CYII='); }

.sites-toggler__link.sites-toggler__link--current {
	color: var(--silver);
	background-color: var(--darkPink);
}

.sites-toggler__link:not(.sites-toggler__link--current) {
	color: var(--gold);
	background-color: var(--whitePink);
}





@media (width <= 767px) {
	.sites-toggler {
		text-align: center;
	}
}





@media (width <= 479px) {

	.sites-toggler { padding-top: 0; }

	.sites-toggler a {
		margin-top: 0;
		width: 100%;
		padding-left: 15px;
		margin-right: 0;
	}

	.sites-toggler a::before { display: none;	}
}