.main-banner-wrapper {
	background-color: var(--normalPink);
	background-image: url(../img/main-banner.png);
	background-position: 75% center;
	background-repeat: no-repeat;
}

.main-banner {
	height: 155px;
	/* background-image: url(../img/main-banner.png); */

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.main-banner h3 {
	margin-top: 0;
	margin-bottom: 0;
	@apply --minionMediumIt;
	color: var(--silver);
	font-size: 53px;
}

.main-banner h4 {
	color: var(--silver);
	@apply --robotoNormal;
	font-size: 18px;
}

.main-banner span {
	font-weight: 700;
}

.main-banner a {
	@apply --robotoBold;
	padding: 5px 0;
	text-align: center;
	width: 100px;
	display: inline-block;
	color: var(--normalPink);
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 2px;
	background-image: linear-gradient(to top, var(--brightGold) 0%, var(--gold) 100%);
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.main-banner a:hover {
	@apply --transitionOpacityEnd;
}




@media (width <= 991px) {
	.main-banner-wrapper {
		background-image: url(../img/main-banner--small.png);
		background-position: 100% center;		
	}
}





@media (width <= 767px) {
	.main-banner-wrapper {
		margin: 0 -15px;
		padding: 0 15px;
	}

	.main-banner h3 {
		font-size: 40px;
	}

	.main-banner h4 {
		font-size: 16px;
		max-width: 230px;
		margin-top: 0;
	}
}





@media (width <= 767px) {
	.main-banner h3,
	.main-banner h4 {
		color: #FFF;
		text-shadow: 0 1px 1px 1px black;
	}
}